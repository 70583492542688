import React, { useState } from 'react';
import { useMediaQuery } from '@mui/material';
import Topbar from './topbar';
import Sidebar from './sidebar';
import { MobileTopbar, MobileSidebar } from './mobileNav';

export default function GlobalNav({ setIsAuthenticated }) {
  const isSmallerScreen = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const [open, setOpen] = useState(false);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <>
      {isSmallerScreen ? (
        <MobileTopbar toggleDrawer={toggleDrawer} />
      ) : (
        <Topbar open={open} setIsAuthenticated={setIsAuthenticated} />
      )}
      {isSmallerScreen ? (
        open && <MobileSidebar open={open} toggleDrawer={toggleDrawer} />
      ) : (
        <Sidebar open={open} toggleDrawer={toggleDrawer} />
      )}
    </>
  );
}
