import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

// Function to format the cobenefits level
export const formatCobenefits = (cobenefits) => {
    if (cobenefits <= 1)  {
        return 1;
    }else if (cobenefits <= 2) {
        return 2;
    }else{
        return 3;
    }
};

// Function to get the background color based on risk level
export const getRiskColor = (riskLevel) => {
    switch (riskLevel) {
        case "very high": return "#D22B25"; // very high
        case "high": return "#FFB649"; // high
        case "medium": return "#FFDE69"; // medium
        case "low": return "#0685CD"; // low
        case "no risk": return "#59A678"; // no risk
        default: return "#000000"; // unknown
    }
};

// Function to get the text color based on risk level
export const getRiskTextColor = (riskLevel) => {
    switch (riskLevel) {
        case "very high": return "#D22B25"; // very high
        case "high": return "#E57C00"; // high
        case "medium": return "#A0770F"; // medium
        case "low": return "#0685CD"; // low
        case "no risk": return "#59A678"; // no risk
        default: return "#000000"; // unknown
    }
};

export const formatAccuracy = (accuracy) => {
    switch (accuracy) {
        case "high": return "A"; 
        case "medium": return "B";
        case "low": return "C"; 
        default: return "Unknown"; // unknown
    }
};

// Function to convert cost range string to a comparable number
export const parseCost = (costRange) => {
    if (costRange.startsWith('<')) return parseFloat(costRange.substring(1)) - 0.1;
    if (costRange.endsWith('+')) return parseFloat(costRange.slice(0, -1)) + 0.1;
    const [low, high] = costRange.split('-').map(parseFloat);
    return (low + high) / 2;
};

export const getRiskLevelText = (risk, t) => {
    switch (risk) {
        case "very high":
            return t("main_page.very_high");
        case "high":
            return t("main_page.high");
        case "medium":
            return t("main_page.medium");
        case "low":
            return t("main_page.low");
        case "no risk":
            return t("main_page.no_risk");
        default:
            return "Unknown";
    }
};
