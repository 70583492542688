import { useEffect, useState } from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';
import { useSearchContext } from '../../SearchContext';
import axios from 'axios';
import i18next from 'i18next';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import CheckIcon from '../../images/icons/check_icon.svg';

const PropertyAddressPaper = () => {
    const { propertyData, hasBasement, isGroundFloor, experiencedSewageBackup } = useSearchContext();
    const [translatedAddress, setTranslatedAddress] = useState("");
    const token = localStorage.getItem('token');
    const navigate = useNavigate();
    const { t } = useTranslation();

    useEffect(() => {
        if (propertyData !== undefined) {
            const lat = propertyData.lat
            const long = propertyData.lon;
            axios.get(`${process.env.REACT_APP_API_BASE_URL}building-coords/osm/reverse/${lat},${long}?language=${i18next.language}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                }
            })
                .then((response) => {
                    const fullTranslatedAddress = response.data
                    setTranslatedAddress(fullTranslatedAddress.address)
                })
                .catch((error) => {
                    console.log(error)
                    setTranslatedAddress("Address not found")
                })
        } else {
            navigate("/dashboard");
        }
    }, [propertyData, i18next.language]);

    const noSelectionMade = !hasBasement.yes && !isGroundFloor.yes && !experiencedSewageBackup.yes;

    return (
        <Paper
            sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                height: 'auto',
                backgroundColor: '#F8F6F4',
            }}
        >
            <Typography sx={{mb:1, fontSize: '12px', color:'#9C8F83'}}>{t('main_page.address')}</Typography>
            <Typography sx={{fontSize: '16px', fontWeight: 500, lineHeight: '17.6px'}}>{translatedAddress}</Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', mt: 1 }}>
                {noSelectionMade ? (
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <img src={CheckIcon} alt='check-icon' style={{ width: 20, height: 20 }} />
                        <Typography variant='addressPaperChecks' sx={{ ml: 1, mr: 2 }}>{t('adaptions_page.no_selection_made')}</Typography>
                    </Box>
                ) : (
                    <>
                        {hasBasement.yes && (
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <img src={CheckIcon} alt='check-icon' style={{ width: 20, height: 20 }} />
                                <Typography variant='addressPaperChecks' sx={{ ml: 1, mr: 2 }}>{t('adaptions_page.basement')}</Typography>
                            </Box>
                        )}
                        {isGroundFloor.yes && (
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <img src={CheckIcon} alt='check-icon' style={{ width: 20, height: 20 }} />
                                <Typography variant='addressPaperChecks' sx={{ ml: 1, mr: 2 }}>{t('adaptions_page.ground_floor')}</Typography>
                            </Box>
                        )}
                        {experiencedSewageBackup.yes && (
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <img src={CheckIcon} alt='check-icon' style={{ width: 20, height: 20 }} />
                                <Typography variant='addressPaperChecks' sx={{ ml: 1, mr: 2 }}>{t('adaptions_page.sewage_backup')}</Typography>
                            </Box>
                        )}
                    </>
                )}
            </Box>
        </Paper>
    )
}

export default PropertyAddressPaper;
