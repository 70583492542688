import React from 'react';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import UserDropdown from './userDropdown';
import styled from '@mui/material/styles/styled';
import LogoHouse from './../../images/logos/logo-house.svg';
import LanguageSelect from '../login/languageSelect';
import MuiAppBar from '@mui/material/AppBar';
import no_notifications_icon from './../../images/navbar/no_notifications_icon.svg';


const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
  })(({ theme }) => ({
    zIndex: theme.zIndex.drawer + 1,
    boxShadow: 'none' ,
    borderBottom: '0.5px solid #E9EBEE',
  }));

const Topbar = ({open, setIsAuthenticated}) => {

    return(
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              backgroundColor: 'white',
              color: 'black',
              pr: '24px', // keep right padding when drawer closed
            }}
          >
            <img src={LogoHouse} alt='logo'></img>
            <Typography
              variant="h1"
              noWrap
              sx={{ flexGrow: 1, ml: 1, paddingY: 0.5}}
            >
              Mitigrate
            </Typography>
            <LanguageSelect fullWidth={false} outlined={false}/>
                <IconButton >
                  <img src={no_notifications_icon} alt='notifications-bell' width='25px' height='25px'></img>
                </IconButton>
                <UserDropdown setIsAuthenticated={setIsAuthenticated}/>
          </Toolbar>
        </AppBar>
    )
}

export default Topbar;