import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
    Box,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    IconButton,
    Dialog,
    DialogContent,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    useTheme,
    useMediaQuery,
    Slide,
} from '@mui/material';
import { Add, Close, Remove } from '@mui/icons-material';
import i18next from 'i18next';
import datasheets from '../../json/datasheets.json';
import pinkDot from '../../images/icons/pink_dot.svg';

const Datasheet = ({ solution, open, handleCloseDialog }) => {
    const { t } = useTranslation();
    const solutionData = datasheets[solution.solution];
    const userLang = i18next.language;
    const moreCobenefits = solutionData.more_cobenefits;
    const [expanded, setExpanded] = useState(false);
    let solutionImage;
    try {
        solutionImage = require(`../../images/solutions/${solution.solution}.jpg`);
    } catch (e) {
        solutionImage = require(`../../images/solutions/image_coming_soon.jpg`); // Fallback if no image
    }
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const renderTextWithLineBreaks = (text) => {
        return text.split('\n').map((line, index) => (
            <Typography key={index} sx={{ mb: 2 }}>
                {line}
            </Typography>
        ));
    };

    const renderCostAndMaintenanceText = (text) => {
        const lines = text.split('\n');
        const complexity = lines[0] ? lines[0] : "";
        const cost = lines[1] ? lines[1] : "";
    
        return (
            <Box sx={{ mt: 2 }}>
                <Box sx={{ display:'flex', flexDirection:'column' }}>
                    <Typography variant="datasheetHeaders3" color="#A8ADB4">{t('adaptions_page.complexity')}</Typography>
                    <Typography variant='datasheetText3' sx={{ mt: 1, mb: 3 }}>{complexity}</Typography>
                </Box>
                <Box sx={{ display:'flex', flexDirection:'column' }}>
                    <Typography variant="datasheetHeaders3" color="#A8ADB4">{t('property_form.cost')}</Typography>
                    <Typography variant='datasheetText3' sx={{ mt: 1, mb: 3 }}>{cost}</Typography>
                </Box>
            </Box>
        );
    };

    const mapEffectivenessToWords = (efficiencyCategory) => {
        let word, color;

        switch(efficiencyCategory) {
            case "low":
                word = t('effectiveness.moderate');
                color = '#B331C833';
                break;
            case "medium":
                word = t('effectiveness.effective');
                color = '#B331C880';
                break;
            case "high":
                word = t('effectiveness.super');
                color = '#B331C8';
                break;
            default:
                word = t('effectiveness.unknown');
                color = '#000000';
                break;
        }

        return (
            <Box
                sx={{
                    display: 'inline-flex',
                    alignItems: 'center',
                    borderRadius: '3px',
                    border: '1px solid var(--Grey-Scale-Colors-Grey-Stronger, #E9EBEE)',
                    padding: '2px 8px',
                    margin: '2px',
                    whiteSpace: 'nowrap',
                    maxWidth: 'fit-content',
                }}
            >
                <Typography sx={{ color: color, marginRight: '4px', display: 'inline' }}>•</Typography>
                <Typography variant="riskTag" sx={{ display: 'inline' }}>{word}</Typography>
            </Box>
        );
    };

    const renderMoreCobenefits = (benefits) => {
        const benefitKeys = Object.keys(benefits);
    
        return benefitKeys.map((key, index) => {
            const benefit = benefits[key];
            const isLast = index === benefitKeys.length - 1; // Check if this is the last Accordion
    
            return (
                <Accordion
                    key={key}
                    expanded={expanded === key}
                    onChange={handleChange(key)}
                    sx={{
                        borderTop: '1px solid',
                        borderTopWidth: '0.5px', 
                        borderBottom: isLast ? '1px solid' : 'none',
                        borderBottomWidth: isLast ? '0.5px' : 'none',
                        borderColor: '#9C8F83',
                        borderLeft: 0,
                        borderRight: 0,
                        boxShadow: 'none',
                        '&:before': {
                            display: 'none',
                        },
                        '&.Mui-expanded': {
                            margin: 0,
                        },
                        '&.MuiPaper-root': {
                            borderRadius: 0,
                        },
                        '& .MuiAccordionSummary-root': {
                            minHeight: '48px', 
                            paddingLeft: 0,
                            paddingRight: 0,
                            paddingTop: 2,
                            paddingBottom: 2,
                            margin: 0,
                            '&.Mui-expanded': {
                                minHeight: '48px',
                                margin: 0, 
                            },
                        },
                        '& .MuiAccordionSummary-content': {
                            margin: 0, 
                            '&.Mui-expanded': {
                                margin: 0,
                            },
                        },
                        '& .MuiAccordionDetails-root': {
                            padding: '8px 16px',
                        },
                    }}
                >
                    <AccordionSummary expandIcon={expanded === key ? <Remove color='primary' /> : <Add color='primary' />}>
                        <Typography variant="h5" sx={{ margin: 0 }}>{benefit.title[userLang]}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {Object.keys(benefit).map((subKey) => {
                            if (subKey === 'title' || subKey === 'img_src') return null;
                            const subBenefit = benefit[subKey];
                            return (
                                <Box key={subKey} sx={{ display: 'flex', flexDirection: 'column', mb: 2, ml: 2 }}>
                                    <Typography variant="datasheetText2" sx={{ mb: 2 }}>
                                        {subBenefit.title[userLang]}
                                    </Typography>
                                    <Typography variant="datasheetText2" color='#747474'>
                                        {subBenefit.description[userLang]}
                                    </Typography>
                                    <Box sx={{ mt: 2, mb: 1 }}>
                                        {mapEffectivenessToWords(subBenefit.impact)}
                                    </Box>
                                </Box>
                            );
                        })}
                    </AccordionDetails>
                </Accordion>
            );
        });
    };

    return (
        <Dialog
        open={open}
        onClose={handleCloseDialog}
        sx={{
            '& .MuiDialog-container': {
                alignItems: 'flex-end',
                justifyContent: 'flex-end',
            },
        }}
        PaperProps={{
            sx: {
                height: '100vh',
                minHeight: '100vh',
                minWidth: '50vw',
                margin: 0,
                right: 0,
                position: 'fixed',
                borderRadius: '0px',
            }
        }}
        >
            <DialogContent sx={{ p: 0 }}>
                <Box sx={{ backgroundColor: '#F8F6F4', px:5, py:5}}>
                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", px: 5}}>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                            <img src={pinkDot} height={10} width={10} alt="Pink Dot" />
                            <Typography sx={{ ml: 1 }}>{t('adaptions_page.mitigrate_solutions')}</Typography>
                        </Box>
                        <IconButton onClick={handleCloseDialog}>
                            <Close />
                        </IconButton>
                    </Box>
                    <Box sx={{ px: 5, my: 6 }}>
                        <Typography variant="h3" sx={{ mb: 2 }}>{solutionData.title[userLang]}</Typography>
                        <Typography variant="datasheetText1" color='#747474'>{solutionData.description[userLang]}</Typography>
                    </Box>
                    {isSmallScreen ? (
                        <Box sx={{ px: 5 }}>
                            {['hazard', 'location', 'function', 'effeciency', 'property_value', 'green_finance'].map((field, index) => (
                                <Box key={field} sx={{ mb: 4, display: 'grid', gridTemplateColumns: '1fr 2fr', gap: 2 }}>
                                    <Typography variant="datasheetHeaders1" color='#A8ADB4'>{solutionData.top_banner[field].title[userLang]}</Typography>
                                    <Typography variant="subtitle1">{solutionData.top_banner[field].description[userLang]}</Typography>
                                </Box>
                            ))}
                        </Box>
                    ) : (
                        <Box sx={{ px: 3 }}>
                            <Table sx={{ borderCollapse: 'separate', borderSpacing: 0 }}>
                                <TableHead>
                                    <TableRow sx={{ borderBottom: 'none' }}>
                                        {['hazard', 'location', 'function', 'effeciency', 'property_value', 'green_finance'].map((field) => (
                                            <TableCell key={field} sx={{ borderBottom: 'none', py: 0.5,  }}>
                                                <Typography variant="datasheetHeaders1" color='#A8ADB4'>
                                                    {solutionData.top_banner[field].title[userLang]}
                                                </Typography>
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow sx={{ borderBottom: 'none' }}>
                                        {['hazard', 'location', 'function', 'effeciency', 'property_value', 'green_finance'].map((field) => (
                                            <TableCell key={field} sx={{ borderBottom: 'none', pt: 0.5,  }}>
                                                <Typography variant="subtitle1">{solutionData.top_banner[field].description[userLang]}</Typography>
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Box>
                    )}

                    <Box sx={{px: 5, mb: 4, mt:6}}>
                        <img src={solutionImage} alt="Solution" style={{ width: '100%', borderRadius: '5px' }} />
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', px:5}}>
                    <Box sx={{ px: 5, my: 5, width:'55%' }}>
                        <Typography variant="h3" sx={{ mb: 3 }}>{solutionData.installation_and_maintenance.title[userLang]}</Typography>
                        <Typography variant="datasheetText2" color='#747474'>{renderTextWithLineBreaks(solutionData.installation_and_maintenance.description[userLang])}</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', px: 5, my: 5, width:'30%' }}>
                        <Box sx={{ mb: 3 }}>
                            <Typography variant="datasheetHeaders2" color='primary' sx={{ mb: 1 }}>{solutionData.installation_and_maintenance.image_text.maintenance.title[userLang]}</Typography>
                            {renderCostAndMaintenanceText(solutionData.installation_and_maintenance.image_text.maintenance.description[userLang])}
                        </Box>
                        <Box>
                            <Typography variant="datasheetHeaders2" color='primary' sx={{ mb: 1 }}>{solutionData.installation_and_maintenance.image_text.installation.title[userLang]}</Typography>
                            {renderCostAndMaintenanceText(solutionData.installation_and_maintenance.image_text.installation.description[userLang])}
                        </Box>
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', px:5 }}>
                    <Box sx={{ px: 5, width:'55%' }}>
                        <Typography variant="h3" sx={{ mb: 3 }}>{t('main_page.co_benefits')}</Typography>
                        <Typography variant="datasheetText2" color='#747474'>{renderTextWithLineBreaks(solutionData.co_benefits.description[userLang])}</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', px: 5, width:'30%' }}>
                        <Box>
                            <Typography variant="datasheetHeaders2" color='primary'>{solutionData.top_banner.effeciency.title[userLang]}</Typography>
                            <Typography sx={{ mt: 3 }}>{mapEffectivenessToWords(solution.efficiency_category)}</Typography>
                        </Box>
                    </Box>
                </Box>
                <Box sx={{ px: 10, my: 5 }}>
                        {renderMoreCobenefits(moreCobenefits)}
                </Box>
            </DialogContent>
        </Dialog>
    );
};

Datasheet.propTypes = {
    solution: PropTypes.object.isRequired,
    open: PropTypes.bool.isRequired,
    handleCloseDialog: PropTypes.func.isRequired,
};

export default Datasheet;
