import React from 'react';
import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTranslation } from "react-i18next";
import { useSearchContext } from "../../SearchContext";
import axios from 'axios';
import LanguageSelect from './languageSelect';
import Alert from '@mui/material/Alert';
import Logo from '../../images/logos/logo.svg';
import { InputLabel } from '@mui/material';
import { ReactComponent as LoaderButton } from '../../images/loaders/loader_white.svg';

const LoginForm = ({ setIsAuthenticated }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loginError, setLoginError] = useState("");
  const { sessionExpired, setSessionExpired } = useSearchContext();
  const [isLoading, setIsLoading] = useState(false);

  const handlePasswordChange = () => {
    navigate('/change-password')
  }

  const handleForgotPassword = () => {
    navigate('/forgot-password')
  }

  const handleSignIn = async (event) => {
    event.preventDefault();
    if (!username || !password) {
      setLoginError("Please enter both username and password.");
      return;
    }
    try {
      localStorage.setItem('username', username);
      setIsLoading(true);
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}user/token/`, {
        username,
        password,
      });

      if (response.status === 200) {
        localStorage.setItem("token", response.data.access);
        await axios.post(`${process.env.REACT_APP_API_BASE_URL}user/log/logins/`, {}, {
          headers: {
            'Authorization': `Bearer ${response.data.access}`
          }
        })
        setIsAuthenticated(true);
        setSessionExpired(false);
        const response_change_password = await axios.get(`${process.env.REACT_APP_API_BASE_URL}user/has-changed-password/`, {
          headers: {
            'Authorization': `Bearer ${response.data.access}`
          }
        })
        const HasChangedPassword = response_change_password.data.has_changed_password
        setIsLoading(false);
        if (!HasChangedPassword) {
          setIsAuthenticated(false);
          navigate('/change-password')
        } else {
          navigate("/site-e-survey");
        }
      } else {
        setLoginError("Invalid username or password.");
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      if (error.response) {
        if (error.response.status === 401) {
          setLoginError("Invalid username or password.");
        } else {
          console.error("Error during login:", error.response.data);
          setLoginError("An error occurred during login. Please try again later.");
        }
      } else if (error.request) {
        console.error("Request error:", error.request);
        setLoginError("Could not connect to the server. Please try again later.");
      } else {
        console.error("Error:", error.message);
        setLoginError("An error occurred. Please try again later.");
      }
    }
  };

  return (
    <Box>
      <Box
        component="img"
        sx={{
          display: { xs: '', md: 'none' },
          position: 'absolute',
          top: 0,
          left: 10,
          height: '10vh',
          width: '10vh',
        }}
        alt="Logo"
        src={Logo}
      />
      {sessionExpired ? (
        <Alert severity="warning" id="session_expired" sx={{ mb: 5 }}>
          Your session is expired. Please log in again.
        </Alert>)
        : ("")}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <Box
          sx={{
            mb: 2,
          }}>
          <Typography variant="bigTitle">
            {t('login.sign_in')}
          </Typography>
          <Typography variant="subtitle1" sx={{ mt: 2 }}>
            {t('login.welcome_back')}
          </Typography>
        </Box>
        <Box component="form" sx={{ width: { xs: '70vw', md: '25vw' } }} onSubmit={handleSignIn}>
          <LanguageSelect fullWidth={true} outlined={true} />
          <InputLabel sx={{ fontSize: '10px', color: '#6A6C6F', mt: 2 }}>{t('login.username')}</InputLabel>
          <TextField
            margin="none"
            color="primary"
            fullWidth
            id="username"
            placeholder={t('login.enter_username')}
            name="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            autoFocus
          />
          <InputLabel sx={{ fontSize: '10px', color: '#6A6C6F', mt: 2 }}>{t('login.password')}</InputLabel>
          <TextField
            margin="none"
            fullWidth
            name="password"
            placeholder={t('login.enter_password')}
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          {/* <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
                /> */}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 3, mb: 2, padding: '10px', borderRadius: '3px' }}
            onClick={handleSignIn}
          >
            {isLoading ? <LoaderButton className='spinner' /> : t('login.sign_in')}
          </Button>
          <Box sx={{ mt: "3px", display: "flex", justifyContent: "space-between" }}>
            <Box className='forgot-password' onClick={handleForgotPassword} sx={{ cursor: "pointer", color: '#A8A8A8', textDecoration: 'underline' }}>
              {t('login.forgot_password')}
            </Box>
            <Box className='change-password' onClick={handlePasswordChange} sx={{ cursor: "pointer", color: '#A8A8A8', textDecoration: 'underline' }}>
              {t('login.change_password')}
            </Box>
          </Box>

          {loginError && (
            <Typography color="error" sx={{ mt: 2 }}>
              {loginError}
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  )
}

export default LoginForm;