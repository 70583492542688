import React from 'react';
import { BarChart } from '@mui/x-charts';
import { useTranslation } from 'react-i18next';

const RiskLevelChart = ({ riskLevels }) => {
    const { t } = useTranslation();

    // Map risk levels to numerical values
    const riskValues = {
        'very high': 4,
        'high': 3,
        'medium': 2,
        'low': 1,
        'no risk': 0
    };

    // Extract translations based on user language
    const translateRiskKey = (key) => {
        return t(`main_page.${key}`);
    };

    // Convert riskLevels object to an array of keys and corresponding values
    const riskKeys = Object.keys(riskLevels);
    const riskData = riskKeys.map(key => riskValues[riskLevels[key]]);
    const translatedRiskKeys = riskKeys.map(key => translateRiskKey(key));

    const valueFormatter = (value) => {
        switch (value) {
            case 0:
                return t('main_page.no_risk');
            case 1:
                return t('main_page.low');
            case 2:
                return t('main_page.medium');
            case 3:
                return t('main_page.high');
            case 4:
                return t('main_page.very_high');
            default:
                return value;
        }
    };

    return (
        <BarChart
            xAxis={[{ 
                scaleType: 'band', 
                data: translatedRiskKeys
            }]}
            series={[{
                data: riskData, valueFormatter
            }]}
            yAxis={[{
                min: 0,
                max: 4,
                colorMap:{
                    type: 'piecewise',
                    thresholds: [0, 1, 2, 3, 4],
                    colors: ['#000', '#59A678', '#0685CD', '#FFDE69', '#FFB649', '#D22B25']
                },
                valueFormatter,
            }]}
            width={450}
            height={300}
            margin={{ left: 70, right: 0, top: 10 }}
        />
    );
};

export default RiskLevelChart;
