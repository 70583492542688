import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Icon1 from '../../images/login_icons/login_icon_1.png';
import Icon2 from '../../images/login_icons/login_icon_2.png';
import Icon3 from '../../images/login_icons/login_icon_3.png';
import Icon4 from '../../images/login_icons/login_icon_4.png';
import { useTranslation } from "react-i18next";

const LoginWelcome = () => {
    const { t } = useTranslation();

    return (
        <Box>
        <Box
        sx={{
            px: 16,
            display: 'flex',
            flexDirection: 'column',
        }}
        >
            <Typography sx={{ color:'#39393D', fontSize:'80px', fontWeight:400, lineHeight:'70px', textAlign: 'center'}}>{ t('login.welcome_to') }</Typography>
            <Typography color="primary" sx={{mb: 7.5, fontSize:'80px', fontWeight:400, lineHeight:'70px', textAlign: 'center'}}>Mitigrate</Typography>
            <Typography variant="body1" sx={{mx: 4.5, mb:4, textAlign: 'center'}}>{ t('login.intro') }</Typography>
        </Box>
        <Box
        sx={{
            display: 'flex',
            width: '100%',
            flexDirection: 'row',
            justifyContent: 'space-around',
            alignItems: 'center',
            px: 6,
            mt: 10,
            mb: 6
        }}>
            <img src={Icon1} width={50} height={50} alt='Icon1'/>
            <img src={Icon2} width={50} height={50} alt='Icon2'/>
            <img src={Icon3} width={50} height={50} alt='Icon3'/>
            <img src={Icon4} width={50} height={50} alt='Icon4'/>
        </Box>
        </Box>
    )
}

export default LoginWelcome;