import React from "react";
import ReactDOM from "react-dom/client";
import "./css/index.css";
import i18n from './i18n/config.js';
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ThemeProvider, createTheme } from '@mui/material';
import { SearchProvider } from "./SearchContext";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const root = ReactDOM.createRoot(document.getElementById("root"));
const userLanguage = localStorage.getItem('userLanguage') || 'en';
i18n.changeLanguage(userLanguage)

const theme = createTheme({
  palette: {
    primary: {
      main: '#D3385C',
      contrastText: "#fff"
    },
    secondary: {
      main: '#DEDEDE',
    },
    info: {
      main: '#8A8A8A',
    },
    success: {
      main: '#F8DEE2',
    },
    error: {
      main: '#D3D6DB',
    },
    warning: {
      main: '#39393D',
    },
  },
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
        textTransform: 'none',
      },
      styleOverrides: {
        root: {
          boxShadow: 'none', // Remove shadow from MuiButtonBase
        },
      },
    },
    MuiButton: {
      defaultProps: {
        textTransform: 'none',
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          boxShadow: 'none', // Remove shadow from MuiButton
          '&:hover': {
            boxShadow: 'none', // Ensure no shadow on hover
          },
          '&:active': {
            boxShadow: 'none', // Ensure no shadow on active state
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        IconComponent: KeyboardArrowDownIcon,
      },
      styleOverrides: {
        root: {
          borderRadius: '5px',
          border: '0.5px solid var(--Grey-Scale-Colors-Grey-Stronger, #E9EBEE)',
          '&:hover': {
            borderColor: 'var(--Primary-Colors-Color-3, #F2BFC6)',
          },
          '&.Mui-focused': {
            borderColor: 'var(--Primary-Colors-Color-3, #D3385C) !important',
            borderWidth: '1px',
          },
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'transparent',
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: 'transparent',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: 'transparent',
          },
        },
        icon: {
          fontSize: '1.15rem',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontFamily: 'Manrope',
          backgroundColor: 'transparent',
          '&:hover': {
            backgroundColor: 'transparent !important',
            boxShadow: 'none',
          },
          '&.Mui-selected': {
            backgroundColor: 'transparent !important',
            boxShadow: 'none',
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: '10px',
          color: '#6A6C6F',
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        margin: 'none',
        color: 'primary',
        fullWidth: true,
        borderRadius: '5px',
      },
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            borderRadius: '5px',
            '& fieldset': {
              borderRadius: '5px',
              border: '0.5px solid var(--Grey-Scale-Colors-Grey-Stronger, #E9EBEE)',
            },
            '&:hover fieldset': {
              borderColor: 'var(--Primary-Colors-Color-3, #F2BFC6)',
            },
            '&.Mui-focused fieldset': {
              borderColor: 'var(--Primary-Colors-Color-3, #D3385C) !important',
              borderWidth: '1px',
            },
            '& input::placeholder': {
              color: '#39393D',
              opacity: 1,
            },
          },
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          boxShadow: 'none',
          border: '1px solid #ccc',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: 'none', // Remove shadow from all Paper elements
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: 'none', // Remove shadow from all Card elements
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: 'none', // Remove shadow from all AppBar elements
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          boxShadow: 'none', // Remove shadow from all Dialog elements
        },
      },
    },
  },
  typography: {
    button: {
      textTransform: 'none',
      fontSize: '16px',
      fontWeight: 500
    },
    fontFamily: "Manrope",
    bigTitle: {
      fontSize: '30px',
      fontWeight: 500,
      lineHeight: '30px'
    },
    h1: {
      fontSize: '21px',
      fontWeight: 600,
      color: '#D33852'
    },
    h2: {
      fontSize: '25px',
      fontWeight: 500
    },
    h3: {
      fontSize: '22px',
      fontWeight: 500
    },
    h5: {
      fontSize: '16px',
      fontWeight: 500
    },
    h6: {
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '16.8px'
    },
    subtitle1: {
      fontSize: '14px',
      fontWeight: 400,
      color: '#6A6C6F',
      lineHeight: '15.4px'
    },
    subtitle2: {
      fontSize: '14px',
      fontWeight: 500,
      color: '#6A6C6F'
    },
    subtitle3: {
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '14px'
    },
    body1: {
      fontSize: '16px',
      fontWeight: 300,
      lineHeight: '19.2px'
    },
    riskTag: {
      fontSize: '12px',
      fontWeight: 500
    },
    solutionTitle: {
      fontSize: '14px',
      fontWeight: 600
    },
    addressPaperChecks: {
      fontSize: '12px',
      fontWeight: 400
    },
    breadcrumbs: {
      fontSize: '10px',
      fontWeight: 400
    },
    reportHeaders: {
      fontSize: '10px',
      fontWeight: 600,
      color: '#D3385C'
    },
    reportHeaders2: {
      fontSize: '11px',
      fontWeight: 600,
      color: '#D3385C'
    },
    reportText: {
      fontSize: '20px',
      fontWeight: 400,
      lineHeight: '24px'
    },
    reportText2: {
      fontSize: '18px',
      fontWeight: 400,
      lineHeight: '21.6px'
    },
    reportText3: {
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '16.8px'
    },
    datasheetHeaders1: {
      fontSize: '10px',
      fontWeight: 600
    },
    datasheetHeaders2: {
      fontSize: '12px',
      fontWeight: 600,
      lineHeight: '12px'
    },
    datasheetHeaders3: {
      fontSize: '14px',
      fontWeight: 600,
      lineHeight: '14px'
    },
    datasheetText1: {
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '19.2px'
    },
    datasheetText2: {
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: '19.2px'
    },
    datasheetText3: {
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '16.8px'
    },
    poppers: {
      fontSize: '12px',
      fontWeight: 500,
    },
  },
});

root.render(
  <React.StrictMode>
    <SearchProvider>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
    </SearchProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
