import LoginWelcome from "./loginWelcome"
import ForgotPasswordForm from "./forgotPasswordForm";
import Box from '@mui/material/Box';
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

const ForgotPassword = ({isAuthenticated,setIsAuthenticated}) => {

    const navigate = useNavigate();
    useEffect(() => {
        if (isAuthenticated) {
            navigate("/site-e-survey");
        }
    }, [isAuthenticated, navigate]);

    return (
        <Box
            className="login-container"
            display="flex"
            height="100vh"
            width='100vw'
        >
            <Box
                width='58%'
                sx={{ 
                    display: { xs: 'none', md: 'flex' },
                    width: '58%',
                    height: '100%',
                    backgroundColor: '#F8F6F4',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <LoginWelcome />
            </Box>
            <Box
                sx={{
                    display: 'flex', 
                    width: { xs: '100%', md: '42%'},
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <ForgotPasswordForm setIsAuthenticated={setIsAuthenticated} />
            </Box>
        </Box>
    );
}

export default ForgotPassword;