import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSearchContext } from './SearchContext';

const useTokenValidation = (setIsAuthenticated) => {
    const token = localStorage.getItem('token');
    const { setSessionExpired, isLoading } = useSearchContext();
    const navigate = useNavigate();

    useEffect(() => {
        if (!setIsAuthenticated) {
            console.error('setIsAuthenticated is not provided');
            return;
        }
        const validateToken = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}user/token/validate/${token}`);
                const data = await response.json();
                if (!data['is_valid']) {
                    setSessionExpired(true);
                    setIsAuthenticated(false);
                    localStorage.removeItem('token');
                    navigate('/login');
                }
            } catch (error) {
                console.error('Error validating token:', error);
                setSessionExpired(true);
                setIsAuthenticated(false);
                navigate('/login');
            }
    };

    validateToken();
    }, [token, setIsAuthenticated, setSessionExpired, navigate, isLoading]);
};

export default useTokenValidation;
