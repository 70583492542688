import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTranslation } from "react-i18next";
import { useSearchContext } from "../../SearchContext";
import axios from 'axios';
import LanguageSelect from './languageSelect';
import Logo from '../../images/logos/logo.svg';
import { InputLabel } from '@mui/material';
import { ReactComponent as LoaderButton } from '../../images/loaders/loader_white.svg';

const ChangePasswordForm = ({ setIsAuthenticated }) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { sessionExpired, changeError, setChangeError } = useSearchContext();
    const [email, setEmail] = useState("");
    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [changeSuccessful, setChangeSuccessful] = useState("");
    const token = localStorage.getItem('token');
    const [isLoading, setIsLoading] = useState(false);

    const handleBack = () => {
        navigate('/login');
    };

    const handleChangePassword = async (event) => {
        event.preventDefault();
        setChangeSuccessful("");
        setChangeError("");

        if (!token || sessionExpired) {
            if (!email || !oldPassword || !newPassword || !confirmPassword) {
                setChangeError("Please enter all fields.");
                return;
            }

            try {
                setIsLoading(true);
                const response = await axios.patch(`${process.env.REACT_APP_API_BASE_URL}user/update/password/`, {
                    email,
                    old_password: oldPassword,
                    new_password: newPassword,
                    confirm_new_password: confirmPassword,
                });

                if (response.status === 200) {
                    setIsLoading(false);
                    setChangeSuccessful("Password was successfully changed. Please log in again.");
                }
            } catch (error) {
                setIsLoading(false);
                if (error.response) {
                    setChangeError(`${error.response.data.error}`);
                } else if (error.request) {
                    console.error("Request error:", error.request);
                    setChangeError("Could not connect to the server. Please try again later.");
                } else {
                    console.error("Error:", error.message);
                    setChangeError("An error occurred. Please try again later.");
                }
            }
        } else {
            if (!newPassword || !confirmPassword) {
                setChangeError("Please enter all fields.");
                return;
            }

            try {
                setIsLoading(true);
                setChangeError("Please change your password to continue.");
                const response = await axios.patch(`${process.env.REACT_APP_API_BASE_URL}user/update/password/authorized/`, {
                    new_password: newPassword,
                    confirm_new_password: confirmPassword,
                }, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                });

                if (response.status === 200) {
                    await axios.patch(`${process.env.REACT_APP_API_BASE_URL}user/has-changed-password/`, {}, {
                        headers: {
                            'Authorization': `Bearer ${token}`,
                        },
                    });
                    setIsAuthenticated(true);
                    setIsLoading(false);
                    navigate('/site-e-survey');
                }
            } catch (error) {
                setIsLoading(false);
                if (error.response) {
                    setChangeError(`${error.response.data.error}`);
                } else if (error.request) {
                    console.error("Request error:", error.request);
                    setChangeError("Could not connect to the server. Please try again later.");
                } else {
                    console.error("Error:", error.message);
                    setChangeError("An error occurred. Please try again later.");
                }
            }
        }
    };

    return (
        <>
            <Box
                component="img"
                sx={{
                    display: { xs: '', md: 'none' },
                    position: 'absolute',
                    top: 0,
                    left: 5,
                    height: '10vh',
                    width: '10vh',
                }}
                alt="Logo"
                src={Logo}
            />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                }}
            >
                <Box
                    sx={{
                        mb: 2,
                    }}
                >
                    <Typography variant="bigTitle">
                        {t('login.change_password')}
                    </Typography>
                </Box>
                <Box component="form" sx={{width: { xs: '70vw', md: '25vw' }}} onSubmit={handleChangePassword}>
                    <LanguageSelect fullWidth={true} outlined={true} />
                    {(!token || sessionExpired) && (
                        <>
                            <InputLabel sx={{ fontSize: '10px', color: '#6A6C6F', mt: 2 }}>{t('login.username')}</InputLabel>
                            <TextField
                                margin="none"
                                color="primary"
                                fullWidth
                                id="email"
                                placeholder={t('login.enter_username')}
                                name="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                autoFocus
                            />
                            <InputLabel sx={{ fontSize: '10px', color: '#6A6C6F', mt: 2 }}>{t('login.old_password')}</InputLabel>
                            <TextField
                                margin="none"
                                color="primary"
                                fullWidth
                                type="password"
                                id="old_password"
                                name="old_password"
                                placeholder={t('login.enter_old_password')}
                                value={oldPassword}
                                onChange={(e) => setOldPassword(e.target.value)}
                            />
                        </>
                    )}
                    <InputLabel sx={{ fontSize: '10px', color: '#6A6C6F', mt: 2 }}>{t('login.new_password')}</InputLabel>
                    <TextField
                        margin="none"
                        color="primary"
                        fullWidth
                        name="new_password"
                        type="password"
                        id="new_password"
                        placeholder={t('login.enter_new_password')}
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                    />
                    <InputLabel sx={{ fontSize: '10px', color: '#6A6C6F', mt: 2 }}>{t('login.confirm_new_password')}</InputLabel>
                    <TextField
                        margin="none"
                        color="primary"
                        fullWidth
                        name="confirm_new_password"
                        type="password"
                        id="confirm_new_password"
                        placeholder={t('login.confirm_new_password')}
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                    <Button
                        type="submit"
                        variant="contained"
                        fullWidth
                        color="primary"
                        sx={{ mt: 3, mb: 2, padding: '10px', borderRadius: '3px' }}
                    >
                        { isLoading ? <LoaderButton className='spinner' /> : t('login.submit') }
                    </Button>
                    <Box
                        onClick={handleBack}
                        sx={{ mt: "3px", display: "flex", justifyContent: "flex-end", cursor: "pointer", color: '#A8A8A8', textDecoration: 'underline' }}
                    >
                        {t('main_page.back')}
                    </Box>
                    {changeError && (
                        <Typography color="error" sx={{ mt: 2 }}>
                            {changeError}
                        </Typography>
                    )}
                    {changeSuccessful && (
                        <Typography color="green" sx={{ mt: 2 }}>
                            {changeSuccessful}
                        </Typography>
                    )}
                </Box>
            </Box>
        </>
    );
};

export default ChangePasswordForm;
