import React, { useEffect } from 'react';
import { useSearchContext } from '../../SearchContext';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import { Breadcrumbs } from '@mui/material';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import HomeIcon from '@mui/icons-material/Home';
import GoogleMap from './google_map';
import useTokenValidation from '../../useTokenValidation';
import { useMediaQuery, useTheme } from '@mui/material';


const MapDash = ({ setIsAuthenticated }) => {
    const { setSearchLat, setSearchLong, setSelectedBuilding, setHasLoadedData, addressNotFound, setAddressNotFound } = useSearchContext()
    const { t } = useTranslation();
    const theme = useTheme();
    const isSmallerScreen = useMediaQuery(theme.breakpoints.down('md'));

    useTokenValidation(setIsAuthenticated);

    useEffect(() => {
        setHasLoadedData(false)
        setSelectedBuilding(null)
        setSearchLat(null)
        setSearchLong(null)
    },[])

    return (
        <>
        { isSmallerScreen ? (
            <Box sx={{display: 'flex', position: 'relative', height: '58vh', borderRadius: '10px', overflow: 'hidden', mt:7}}>
                <GoogleMap/>
            </Box>
        ) : (
            <Box>
                <Box sx={{mt:9, mb:1.5, display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%'}}>
                    <Breadcrumbs sx={{ml: 3}} separator="›">
                    <Link underline="hover" fontSize='12px' color="inherit" href="/site-e-survey">
                            <HomeIcon sx={{ verticalAlign: 'middle' }} fontSize="inherit" color='primary' />
                        </Link>
                        <Link underline="hover" color="inherit" href="/site-e-survey"><Typography variant='breadcrumbs'>Site-E-Survery</Typography></Link>
                    </Breadcrumbs>
                <Box sx={{ mr: 3 }}>
                    <Typography variant="breadcrumbs">{t('adaptions_page.where_info_coming_from')}</Typography>
                    <Link fontSize="10px" color="primary" href="/site-e-survey">{t('adaptions_page.learn_more')}</Link>
                </Box>
            </Box>
            <Box
            sx={{
                display: 'flex',
                position: 'relative',
                ml: 3,
                mr: 3,
                borderRadius: '10px',
                height: '84vh',
                overflow: 'auto',
            }}
            >
        {addressNotFound ? (
        <Box                     
            sx={{
            position: 'absolute',
            top: '50%',
            left: '65%',
            transform: 'translate(-50%, -50%)',
            zIndex: 1,
            width: '30%'
        }}>
            <Alert severity="warning" onClose={() => setAddressNotFound(false)}>
                <AlertTitle>Property information for the requested address is not yet in the database.</AlertTitle>
                If you think it should be in the database, please contact <strong>webapp-support@mitigrate.com.</strong>
            </Alert>
        </Box>)
        : ("")} 
                <GoogleMap setIsAuthenticated={setIsAuthenticated}/>
            </Box>
            </Box>
            )
        }
        </>
    );
    }

export default MapDash;