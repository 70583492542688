import React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import { Avatar, IconButton, Typography } from '@mui/material';
import { useNavigate } from "react-router-dom";
import { useSearchContext } from '../../SearchContext';

export default function UserDropdown({setIsAuthenticated}) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const navigate = useNavigate();
    const username = localStorage.getItem('username');

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        setAnchorEl(null);
        localStorage.removeItem("token");
        setIsAuthenticated(false);
        navigate("/login");
    };

    return (
        <>
            <IconButton onClick={handleClick}>
            {username&&
                <Avatar sx={{ width: 32, height: 32 }}>{username[0].toUpperCase()}</Avatar>
            }
            </IconButton>
            <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                <MenuItem onClick={handleClose} disabled>Profile</MenuItem>
                <MenuItem onClick={handleClose} disabled>My properties</MenuItem>
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
        </>
    );
}