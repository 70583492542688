import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Box, Typography, Checkbox, FormControlLabel, FormGroup, Dialog, DialogContent, IconButton, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { ReactComponent as LoaderButton } from '../../../images/loaders/loader_white.svg';
import { ReactComponent as LoaderButtonBlack } from '../../../images/loaders/loader_black.svg';
import { useSearchContext } from '../../../SearchContext';
import { Close } from "@mui/icons-material";
import '../../../css/loaders.css';
import { ReactComponent as DownloadButton } from '../../../images/icons/download_icon.svg';
import axios from "axios";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const mapTypes = ["Satellite", "Roadmap", "Hybrid", "Terrain"];
const reportTypes = ["Policyholder", "Inspector"];

const DropDownContent = ({ types, selectedType, handleSelect, t }) => (
    <FormControl fullWidth variant="outlined">
        <Select
            value={selectedType}
            onChange={handleSelect}
            MenuProps={{
                PaperProps: {
                    sx: {
                        backgroundColor: '#F8F6F4',
                        borderRadius: '8px',
                        boxShadow: 'none',
                    },
                },
            }}
            IconComponent={KeyboardArrowDownIcon}
        >
            {types.map((type) => (
                <MenuItem
                    key={type}
                    value={type.toLowerCase()}
                    sx={{
                        '&:hover': {
                            backgroundColor: '#E9EBEE',
                        },
                        '&.Mui-selected': {
                            backgroundColor: '#F8F6F4',
                        },
                    }}
                >
                    {t(`pdf.${type.toLowerCase()}`)}
                </MenuItem>
            ))}
        </Select>
    </FormControl>
);

const GetPDF = ({viewReport}) => {
    const [includeBlueSpots, setIncludeBlueSpots] = useState(true);
    const [includeStreams, setIncludeStreams] = useState(true);
    const [mapType, setMapType] = useState("satellite"); // Store in lowercase
    const [reportType, setReportType] = useState("policyholder"); // Store in lowercase
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { propertyData, blueSpotData, streamsData } = useSearchContext();
    const [isLoading, setIsLoading] = useState(false);
    const { t } = useTranslation();
    const token  = localStorage.getItem('token');
    const { returnPeriod, riskYear } = useSearchContext();

    const getPdf = async () => {
        setIsModalOpen(false);
        setIsLoading(true);
        try {
            const res = await axios.post(`${process.env.REACT_APP_API_BASE_URL}report/create-report/${propertyData.building_id}/${t("language")}/?map_type=${mapType}&plot_blue_spots=${includeBlueSpots}&plot_streams=${includeStreams}&report_type=${reportType}&return_period=${returnPeriod}&year=${riskYear}`, 
                {
                    "building_info": propertyData,
                    "blue_spots": blueSpotData
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                }
            );
            const response_json = await res.data;
            window.location.replace(response_json.url);
        } catch (e) {
            console.error(e);
        }
        setIsLoading(false);
    };  

    const handleMapTypeSelect = (event) => {
        setMapType(event.target.value.toLowerCase()); // Save in lowercase
    };

    const handleReportTypeSelect = (event) => {
        setReportType(event.target.value.toLowerCase()); // Save in lowercase
    };

    const handleModalOpen = () => {
        setIsModalOpen(true);
    };

    const handleModalClose = () => {
        setIsModalOpen(false);
    };

    const ModalContent = (
        <Dialog
            open={isModalOpen}
            onClose={handleModalClose}
            sx={{
                '& .MuiDialog-container': {
                    alignItems: 'flex-end',
                    justifyContent: 'flex-end',
                },
            }}
            PaperProps={{
                sx: {
                    height: '100vh',
                    minHeight: '100vh',
                    width: '28%',
                    margin: 0,
                    right: 0,
                    position: 'fixed',
                    borderRadius: '0px',
                }
            }}
        >
            <Box sx={{ display: "flex", justifyContent: "end", backgroundColor: '#F8F6F4' }}>
                <IconButton onClick={handleModalClose}>
                    <Close />
                </IconButton>
            </Box>
            <DialogContent sx={{ backgroundColor: '#F8F6F4', height: '100%', padding: '24px' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2, mb: 4 }}>
                    <Typography variant="h3">{t('pdf.custom_pdf_intro')}</Typography>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: 2, width: '100%' }}>
                    <Typography sx={{ fontSize: '12px', color: '#6A6C6F', marginBottom:0 }} gutterBottom>
                        {t('pdf.select_report_type')}
                    </Typography>
                    <DropDownContent types={reportTypes} selectedType={reportType} handleSelect={handleReportTypeSelect} t={t} />
                    <Typography sx={{ fontSize: '12px', color: '#6A6C6F', marginBottom:0 }} gutterBottom>
                        {t('pdf.select_map_type')}
                    </Typography>
                    <DropDownContent types={mapTypes} selectedType={mapType} handleSelect={handleMapTypeSelect} t={t} />
                    <Box mt={2} mb={2} sx={{ width: '100%' }}>
                        <Typography sx={{ fontSize: '12px', color: '#6A6C6F' }} gutterBottom>
                            {t('pdf.custom_map')}
                        </Typography>
                        <FormGroup row>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        id="includeBlueSpots"
                                        name="includeBlueSpots"
                                        checked={includeBlueSpots}
                                        onChange={() => setIncludeBlueSpots(!includeBlueSpots)}
                                    />
                                }
                                label={t('pdf.include_blue_spots')}
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        id="includeStreams"
                                        name="includeStreams"
                                        checked={includeStreams}
                                        onChange={() => setIncludeStreams(!includeStreams)}
                                    />
                                }
                                label={t('pdf.include_streams')}
                            />
                        </FormGroup>
                    </Box>
                    <Button
                        variant="contained"
                        fullWidth
                        onClick={() => {
                            getPdf();
                            handleModalClose();
                        }}
                    >
                        {t('main_page.download_report')}
                    </Button>
                </Box>
            </DialogContent>
        </Dialog>
    );

    return (
        <Box>
            {viewReport ? (            
                    <Button
                    variant="contained"
                    color="primary"
                    sx={{display: 'flex',
                        color: 'white',
                        padding: '10px 15px',
                        height: '46px',
                        borderRadius: '3px',
                        boxShadow: 'none',
                        ':hover': {
                            boxShadow: 'none',
                            backgroundColor: '#B52C4C'
                        }
                    }}
                    onClick={handleModalOpen}>
                    {!isLoading ? t('main_page.download_report') : <LoaderButton className='pdf-spinner' />}
                    </Button>
            ):(            
            <Button
                variant="contained"
                sx={{
                    display: 'flex',
                    backgroundColor: 'transparent',
                    ml: 1,
                    height: '46px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '5px',
                    border: '1px solid #F8DEE2',
                    borderRadius: '3px',
                    boxShadow: 'none',
                    ':hover': {
                        backgroundColor: '#F8DEE2',
                        boxShadow: 'none'
                    }
                }}
                onClick={handleModalOpen}
            >
                {!isLoading ? <DownloadButton style={{ width: '20px', height: '20px' }} /> : <LoaderButtonBlack className='pdf-spinner' />}
            </Button>)}

            <Dialog open={isModalOpen} onClose={handleModalClose} sx={{ height: '100vh' }}>
                {ModalContent}
            </Dialog>
        </Box>
    );
};

export default GetPDF;
