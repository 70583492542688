import React, { useState } from 'react';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { styled } from '@mui/system';
import { useSearchContext } from '../../SearchContext';
import { useTranslation } from 'react-i18next';


const DROPDOWN_WIDTH = 170; // Set the width for the dropdown

const regulations = [
  'Regulation 1',
  'Regulation 2',
  'Regulation 3'
];

const SelectedRisksBox = styled(Box)({
  display: 'flex',
  paddingTop: 1,
  paddingBottom: 1,
  paddingLeft: 4,
  paddingRight: 4,
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '3px',
  border: '1px solid #E3E3E3',
  background: '#F7F7F7',
});

const AdaptionsFilters = ({ uniqueRisks }) => {
  const { risksToFilter, setRisksToFilter } = useSearchContext();
  const { t } = useTranslation();
  const [selectedRegulation, setSelectedRegulation] = useState('');

  const handleRiskChange = (event) => {
    const {
      target: { value },
    } = event;
    setRisksToFilter(
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const handleRegulationChange = (event) => {
    setSelectedRegulation(event.target.value);
  };

  return (
    // Risks filter
    <Box sx={{ display: 'inline-flex', alignItems: 'flex-start' }}>
      <FormControl sx={{ width: DROPDOWN_WIDTH }}>
        <Select
          displayEmpty
          value={risksToFilter}
          onChange={handleRiskChange}
          multiple
          renderValue={(selected) => selected.length ? (
            <Box sx={{display:'inline-flex', justifyContent:'center', alignItems:'center'}}>
              {t('main_page.risks')+': '}
            <SelectedRisksBox>
              {selected.length}
            </SelectedRisksBox>
            </Box>
          ) : t('main_page.filter_by_risk')}
          sx={{
            width: DROPDOWN_WIDTH,
            padding: '10 15px',
            height: '38px',
          }}
        >
          {uniqueRisks.map((risk) => (
            <MenuItem 
            key={risk} 
            value={risk}               
            sx={{ py: 0, px: 0, my: 0, mx: 0 }}
            >
              <Checkbox checked={risksToFilter.indexOf(risk) > -1} />
              <ListItemText primary={t(`main_page.${risk}`)} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {/* Regulations filter */}
      {/* <FormControl sx={{ width: DROPDOWN_WIDTH }}>
        <Select
          displayEmpty
          value={selectedRegulation}
          onChange={handleRegulationChange}
          input={
            <OutlinedInput
              sx={{
                height: '32px',
                padding: '0 10px',
                display: 'flex',
                alignItems: 'center',
              }}
            />
          }
          renderValue={(selected) => selected ? selected : t('Filter by regulation')}
          MenuProps={MenuProps}
          sx={{
            display: 'flex',
            alignItems: 'center',
            borderRadius: '5px',
            border: '0.5px solid #E3E3E3',
            height: '32px',
            '.MuiSelect-select': {
              padding: '0 10px',
              display: 'flex',
              alignItems: 'center',
              color: selectedRegulation ? 'inherit' : '#a0a0a0', // Show placeholder color if no selection
            },
            width: DROPDOWN_WIDTH,
          }}
          // disabled
        >
          {regulations.map((regulation) => (
            <MenuItem key={regulation} value={regulation} disabled>
              <ListItemText primary={regulation} />
            </MenuItem>
          ))}
        </Select>
      </FormControl> */}
    </Box>
  );
}

export default AdaptionsFilters;
