import React, { useState, useRef } from 'react';
import { useSearchContext } from '../../SearchContext';
import PropTypes from 'prop-types';
import {
    Box,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    IconButton,
    Collapse,
    Tabs,
    Tab,
    Popper,
    Paper,
    Button
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { useTranslation } from 'react-i18next';
import GetPDF from './report/getPDF';
import AdaptionsFilters from './adaptionsFilters';
import InfoIcon from '../../images/icons/more_info_icon.svg';
import Datasheet from './datasheet';
import { formatCobenefits, getRiskColor, getRiskTextColor, formatAccuracy, parseCost } from './formattingUtils';

const AdaptionTable = ({ riskSolutions, riskLevels, viewReport, setViewReport }) => {
    const { risksToFilter } = useSearchContext();
    const { t } = useTranslation();

    const [expandedRow, setExpandedRow] = useState(null);
    const [tabValue, setTabValue] = useState(0);
    const [sortingCriteria, setSortingCriteria] = useState('effectiveness');

    // Popper state
    const [anchorEl, setAnchorEl] = useState(null);
    const [popperContent, setPopperContent] = useState('');

    // Dialog state
    const [openDatasheet, setOpenDatasheet] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedSolution, setSelectedSolution] = useState(null);

    const [expanded, setExpanded] = useState(false);

    const handleRiskClick = () => {
        setExpanded(!expanded);
    };

    const handleRowClick = (index) => {
        setExpandedRow(expandedRow === index ? null : index);
    };

    const mapEffectivenessToWords = (efficiencyCategory) => {
        let word, color;

        switch(efficiencyCategory) {
            case "low":
                word = t('effectiveness.moderate');
                color = '#B331C833';
                break;
            case "medium":
                word = t('effectiveness.effective');
                color = '#B331C880';
                break;
            case "high":
                word = t('effectiveness.super');
                color = '#B331C8';
                break;
            default:
                word = t('effectiveness.unknown');
                color = '#000000';
                break;
        }

        return (
            <Box
                sx={{
                    display: 'inline-flex',
                    alignItems: 'center',
                    borderRadius: '3px',
                    border: '1px solid var(--Grey-Scale-Colors-Grey-Stronger, #E9EBEE)',
                    padding: '2px 8px',
                    margin: '2px',
                    whiteSpace: 'nowrap',
                    maxWidth: 'fit-content',
                }}
            >
                <Typography sx={{ color: color, display: 'inline', fontSize: '25px' }}>•</Typography>
                <Typography variant="riskTag" sx={{ display: 'inline' }}>{word}</Typography>
            </Box>
        );
    };


    const formatRisks = (risks) => {
        if (!expanded && risks.length > 1) {
            return (
                <Box>
                    <Box
                        sx={{
                            display: 'inline-block',
                            borderRadius: '3px',
                            border: `1px solid ${getRiskColor(riskLevels[risks[0]])}`,
                            padding: '2px 8px',
                            margin: '2px',
                            backgroundColor: getRiskColor(riskLevels[risks[0]]) + '33',
                            maxWidth: 'fit-content',
                            whiteSpace: 'nowrap'
                        }}
                    >
                        <Typography variant="riskTag" color={getRiskTextColor(riskLevels[risks[0]])}>
                            {t(`main_page.${risks[0]}`)}
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            display: 'inline-block',
                            borderRadius: '3px',
                            border: '1px solid rgba(0, 0, 0, 0.20)',
                            background: '#EEEEEE',
                            padding: '2px 4px',
                            margin: '2px',
                            whiteSpace: 'nowrap',
                            maxWidth: 'fit-content',
                            cursor: 'pointer',
                        }}
                        onClick={handleRiskClick}
                    >
                        <Typography variant="riskTag">
                            +{risks.length - 1}
                        </Typography>
                    </Box>
                </Box>
            );
        }

        return risks.map((risk, idx) => {
            const riskLevel = riskLevels[risk];
            const color = getRiskColor(riskLevel);
            const textColor = getRiskTextColor(riskLevel);
            const background = `${color}33`; // 20% opacity
            const border = `1px solid ${color}`;

            return (
                <Box
                    key={idx}
                    sx={{
                        display: 'flex',
                        borderRadius: '3px',
                        border: border,
                        background: background,
                        padding: '2px 8px',
                        margin: '2px',
                        whiteSpace: 'nowrap',
                        maxWidth: 'fit-content',
                        cursor: 'pointer',
                    }}
                    onClick={() => setExpanded(!expanded)}
                >
                    <Typography variant="riskTag" color={textColor}>
                        {t(`main_page.${risk}`)}
                    </Typography>
                </Box>
            );
        });
    };

    const filterSolutions = (row) => {
        return risksToFilter.length === 0 || risksToFilter.some(risk => row.risks.includes(risk));
    };

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
        switch (newValue) {
            case 0:
                setSortingCriteria('effectiveness');
                break;
            case 1:
                setSortingCriteria('affordable');
                break;
            case 2:
                setSortingCriteria('greenest');
                break;
            default:
                setSortingCriteria('effectiveness');
                break;
        }
    };

    const handleViewReport = () => {
        setViewReport(true);
    };

    const handleMouseEnter = (event, content) => {
        setAnchorEl(event.currentTarget);
        setPopperContent(content);
    };

    const handleMouseLeave = () => {
        setAnchorEl(null);
        setPopperContent('');
    };

    const handleOpenDialog = (solution) => {
        console.log(solution);
        setSelectedSolution(solution);
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setSelectedSolution(null);
        setOpenDialog(false);
    };

    const handleOpenDatasheet = (solution) => {
        setOpenDatasheet(true);
        setSelectedSolution(solution);
    };

    const handleCloseDatasheet = () => {
        setOpenDatasheet(false);
        setSelectedSolution(null);
    };

    // Sorting logic
    const sortSolutions = (solutions, criteria) => {
        switch (criteria) {
            case 'effectiveness':
                return solutions.sort((a, b) => b.efficiency - a.efficiency);
            case 'affordable':
                return solutions.sort((a, b) => parseCost(a.cost_range) - parseCost(b.cost_range));
            case 'greenest':
                return solutions.sort((a, b) => b.cobenefits - a.cobenefits);
            default:
                return solutions;
        }
    };

    // Process and sort risk solutions
    const processedRiskSolutions = sortSolutions(
        Object.keys(riskSolutions).map(key => {
            const { cost_range, efficiency, efficiency_category, cobenefits, accuracy, size, unit_r, currency, risks } = riskSolutions[key];
            return {
                solution: key,
                cost_range,
                efficiency,
                efficiency_category,
                cobenefits,
                accuracy,
                size,
                unit_r,
                currency,
                risks
            };
        }),
        sortingCriteria
    );

        // Getting unique risks for filtering purposes
        const getUniqueRisks = (riskSolutions) => {
            const uniqueRisks = new Set();
            Object.keys(riskSolutions).forEach(key => {
                riskSolutions[key].risks.forEach(risk => uniqueRisks.add(risk));
            });
            return Array.from(uniqueRisks);
        };
        const uniqueRisks = getUniqueRisks(processedRiskSolutions);

    return (
        <Box>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: '136px' }}>
                <Box>
                    <Typography variant="h3" gutterBottom>
                        {t('adaptions_page.proposed_adaptions_overview')}
                    </Typography>
                    <Typography variant="h6" sx={{color:'#A8ADB4'}}gutterBottom>
                    {t('adaptions_page.measures_ranked_by_effectiveness')}
                    </Typography>
                </Box>
                <Box sx={{display: 'inline-flex', flexDirection: 'row-reverse'}}>                
                    <GetPDF viewReport={viewReport}/>
                    <Button
                    variant="contained"
                    color="primary"
                    sx={{display: 'flex',
                        color: 'white',
                        padding: '10px 15px',
                        height: '46px',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '5px',
                        borderRadius: '3px',
                        boxShadow: 'none',
                        ':hover': {
                            boxShadow: 'none',
                            backgroundColor: '#B52C4C'
                        }
                    }}
                    onClick={handleViewReport}>
                    {t('adaptions_page.view_report')}
                    </Button>
                </Box>
            </Box>
            <Box sx={{ borderBottom: 1, borderTop: 1, borderColor: 'divider', display: 'flex', justifyContent: 'space-between', alignItems:'center' }}>
                <Box>
                    <Tabs
                        value={tabValue}
                        onChange={handleTabChange}
                        aria-label="adaption tabs"
                        sx={{
                            backgroundColor: '#F2F2F2',
                            borderRadius: '5px',
                            width: 'fit-content',
                            padding:0,
                            my: 1,
                            justifyContent: 'center',
                            alignItems: 'center',
                            '& .MuiTab-root': {
                                backgroundColor: '#F2F2F2',
                                px: 1,
                                py: 0.5,
                                mx: 1,
                                color: 'black',
                                fontSize: '12px',
                                fontWeight: 500,
                                borderRadius: '5px',
                                minHeight: 'unset',
                            },
                            '& .MuiTab-root.Mui-selected': {
                                backgroundColor: 'white',
                                color: 'black',
                                my: 0.5,
                                mx: 1,
                                px: 1,
                                py: 1,
                                minHeight: 'unset',
                            },
                            '& .MuiTabs-indicator': { display: 'none' },
                        }}
                    >
                        <Tab label={t('adaptions_page.most_effective')} />
                        <Tab label={t('adaptions_page.most_affordable')} />
                        <Tab label={t('adaptions_page.greenest')} />
                    </Tabs>
                </Box>
                <Box sx={{ my: 1 }}>
                <AdaptionsFilters uniqueRisks={uniqueRisks}/>
                </Box>
            </Box>
            <Table>
            <TableHead sx={{whiteSpace: 'nowrap'}}>
                <TableRow sx={{ backgroundColor: '#F9FAFB' }}>
                    <TableCell sx={{ paddingRight: '0px', marginRight: 0 }}>
                        {t('main_page.ranked_solutions')}
                    </TableCell>
                    <TableCell>
                            {t('main_page.efficiency')}
                            <img
                                src={InfoIcon}
                                alt="info-icon"
                                style={{ cursor: 'pointer', verticalAlign: 'middle', marginLeft: 3 }}
                                onMouseEnter={(e) => handleMouseEnter(e, t("poppers.effectivity"))}
                                onMouseLeave={handleMouseLeave}
                            />
                    </TableCell>
                    <TableCell>
                            {t('main_page.risk_type')}
                            <img
                                src={InfoIcon}
                                alt="info-icon"
                                style={{ cursor: 'pointer', verticalAlign: 'middle', marginLeft: 3 }}
                                onMouseEnter={(e) => handleMouseEnter(e, t("poppers.risk_type"))}
                                onMouseLeave={handleMouseLeave}
                            />
                    </TableCell>
                    <TableCell>
                            {t('main_page.co_benefits')}
                            <img
                                src={InfoIcon}
                                alt="info-icon"
                                style={{ cursor: 'pointer', verticalAlign: 'middle', marginLeft: 3 }}
                                onMouseEnter={(e) => handleMouseEnter(e, t("poppers.co_benefits"))}
                                onMouseLeave={handleMouseLeave}
                            />
                    </TableCell>
                    <TableCell>{t('main_page.units')}</TableCell>
                    <TableCell>{t('main_page.cost')}</TableCell>
                    <TableCell>
                            {t('main_page.score')}
                            <img
                                src={InfoIcon}
                                alt="info-icon"
                                style={{ cursor: 'pointer', verticalAlign: 'middle', marginLeft: 3 }}
                                onMouseEnter={(e) => handleMouseEnter(e, t("poppers.accuracy"))}
                                onMouseLeave={handleMouseLeave}
                            />
                    </TableCell>
                </TableRow>
            </TableHead>
                <TableBody>
                    {processedRiskSolutions.filter(filterSolutions).map((row, index) => (
                        <React.Fragment key={index}>
                            <TableRow 
                                sx={{
                                    '& > *': { // Apply to all cells within the row
                                        borderBottom: 0,
                                    },
                                }}
                            >
                                <TableCell
                                    sx={{
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        marginRight: 0, 
                                        paddingRight: 0, 
                                        borderBottom: 0,
                                    }}
                                >
                                        <IconButton sx={{ ml: -1.5, mr: -1, color:'#A8ADB4' }} onClick={() => handleRowClick(index)}>
                                            {expandedRow === index ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                                        </IconButton>
                                        <Typography variant="solutionTitle">
                                            {t(`solution.${row.solution}.name`)}
                                        </Typography>
                                        <img 
                                            src={InfoIcon} 
                                            alt="info-icon" 
                                            style={{ cursor: 'pointer', verticalAlign: 'middle', marginLeft:3 }} 
                                            onClick={() => handleOpenDatasheet(row)} 
                                        />
                                </TableCell>
                                <TableCell sx={{ marginRight: 0, paddingRight: 0, borderBottom: 0 }}>{mapEffectivenessToWords(row.efficiency_category)}</TableCell>
                                <TableCell sx={{ marginRight: 0, paddingRight: 0, borderBottom: 0 }}>{formatRisks(row.risks)}</TableCell>
                                <TableCell sx={{ marginRight: 0, paddingRight: 0, borderBottom: 0 }}>{formatCobenefits(row.cobenefits)}</TableCell>
                                <TableCell sx={{ marginRight: 0, paddingRight: 0, borderBottom: 0 }}>{Math.round(row.size)} {t(`${row.unit_r}`)}</TableCell>
                                <TableCell sx={{ marginRight: 0, paddingRight: 0, borderBottom: 0 }}>{row.cost_range}</TableCell>
                                <TableCell sx={{ marginRight: 0, paddingRight: 0, borderBottom: 0 }}>
                                    <Box
                                        sx={{
                                            display: 'inline-flex',
                                            alignItems: 'center',
                                            borderRadius: '3px',
                                            border: '1px solid var(--Grey-Scale-Colors-Grey-Stronger, #E9EBEE)',
                                            padding: '2px 8px',
                                            margin: '2px',
                                            whiteSpace: 'nowrap',
                                            maxWidth: 'fit-content',
                                        }}
                                    >{formatAccuracy(row.accuracy)}
                                    </Box>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
                                    <Collapse in={expandedRow === index} timeout="auto" unmountOnExit>
                                        <Typography variant="body2" style={{ padding: '16px' }}>
                                            {t(`solution.${row.solution}.long_description`)}
                                        </Typography>
                                    </Collapse>
                                </TableCell>
                            </TableRow>
                        </React.Fragment>
                    ))}
                </TableBody>
            </Table>
            <Popper open={Boolean(anchorEl)} anchorEl={anchorEl} placement="top" disablePortal>
                <Paper sx={{ padding: 1, maxWidth: 175, wordWrap: 'break-word', boxShadow: '0px 0px 23px 0px rgba(0, 0, 0, 0.10)', lineHeight: '13.2px' }}>
                    <Typography variant='poppers'>{popperContent}</Typography>
                </Paper>
            </Popper>
            {selectedSolution && <Datasheet solution={selectedSolution} open={openDatasheet} handleCloseDialog={handleCloseDatasheet} />}
        </Box>
    );
};

AdaptionTable.propTypes = {
    riskSolutions: PropTypes.object,
    riskLevels: PropTypes.object,
    viewReport: PropTypes.bool,
    setViewReport: PropTypes.func
};

export default AdaptionTable;