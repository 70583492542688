import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import water_path_legend from "../../images/legend/water_path_legend.svg";
import blue_spot_legend from "../../images/legend/blue_spot_legend.svg";
import plot_boundary_legend from "../../images/legend/plot_boundary_legend.svg";

const MapLegend = () => {
  const { t } = useTranslation();

    return(
        <Box
        sx={{
          position: 'absolute',
          display: 'flex',
          flexDirection: 'row',
          width: '90%',
          justifyContent: 'space-evenly',
          bottom: 20,
          left: 0,
          right: 0,
          margin: 'auto',
          backgroundColor: 'white',
          padding: 0.5,
          borderRadius: '5px',
          zIndex: 1000,
        }}
      >
        <img src={water_path_legend} alt='water_path_legend' />
        <Typography variant='h6' sx={{ mr: 1 }}>{t('adaptions_page.water_flow_path')}</Typography>
        <img src={blue_spot_legend} alt='blue_spot_legend' />
        <Typography variant='h6' sx={{ mr: 1 }}>{t('adaptions_page.water_gathering')}</Typography>
        <img src={plot_boundary_legend} alt='plot_boundary_legend' />
        <Typography variant='h6'>{t('adaptions_page.plot_boundary')}</Typography>
      </Box>
    )
}

export default MapLegend;