import React, { useState, useEffect } from 'react';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import { useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from "react-i18next";
import i18next from 'i18next';
import EnIcon from '../../images/flags/en-icon.png';
import NoIcon from '../../images/flags/no-icon.png';

const LanguageSelect = ({ fullWidth, outlined }) => {
    const { t } = useTranslation();
    const [userLanguage, setUserLanguage] = useState(i18next.language);
    const theme = useTheme();
    const isSmallerScreen = useMediaQuery(theme.breakpoints.down('md'));
    const shouldUseSmallerStyles = isSmallerScreen && !outlined;

    useEffect(() => {
        // Set the selected language in local storage
        localStorage.setItem('userLanguage', userLanguage);
    }, [userLanguage]);

    const handleChange = (event) => {
        i18next.changeLanguage(event.target.value);
        setUserLanguage(event.target.value);
    };

    return (
        <Box>
        {outlined && <InputLabel sx={{fontSize:'10px', color:'#6A6C6F'}}>{ t('login.language') }</InputLabel>}
        <Select
            {...fullWidth && fullWidth}
            variant="outlined"
            label="language"
            value={userLanguage}
            onChange={handleChange}
            sx={{
                borderRadius: '5px',
                border: '0.5px solid var(--Grey-Scale-Colors-Grey-Stronger, #E9EBEE)',
                display: "flex", 
                flexDirection: 'row', 
                '& legend': { display: 'none' },
                '&:hover': {
                    borderColor: 'var(--Primary-Colors-Color-3, #F2BFC6)',
                },
                '&.Mui-focused': {
                    borderColor: 'var(--Primary-Colors-Color-3, #D3385C) !important',
                    borderWidth: '1px',
                },
                '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'transparent',
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'transparent',
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'transparent',
                },
                ...(!outlined && {
                    border: 0,
                    boxShadow: 'none',
                    '.MuiOutlinedInput-notchedOutline': { border: 0 },
                    "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                    { border: 0, },
                    "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                    { border: 0, },
                    '&.Mui-focused': {
                        border: 0,
                    },
                }),
            }}
        >
            <MenuItem 
            value={"en"}                     
            sx={{
                justifyContent: shouldUseSmallerStyles ? 'center' : 'flex-start',
                backgroundColor: 'transparent',
                '&:hover': {
                    backgroundColor: 'transparent !important',
                    boxShadow: 'none', // Remove shadow on hover
                },
                '&.Mui-selected': {
                    backgroundColor: 'transparent !important',
                    boxShadow: 'none', // Remove shadow when selected
                },
            }}>
                <Box sx={{ display: "flex", flexDirection: 'row', alignItems: 'center' }}>
                    <img src={EnIcon} width={20} height={20} alt='English' />
                    {!shouldUseSmallerStyles && <Typography sx={{ ml: 1 }}>English</Typography>}
                </Box>
            </MenuItem>
            <MenuItem 
            value={"no"} 
            sx={{
                justifyContent: shouldUseSmallerStyles ? 'center' : 'flex-start',
                backgroundColor: 'transparent',
                '&:hover': {
                    backgroundColor: 'transparent !important',
                    boxShadow: 'none', // Remove shadow on hover
                },
                '&.Mui-selected': {
                    backgroundColor: 'transparent !important',
                    boxShadow: 'none', // Remove shadow when selected
                },
            }}>
                <Box sx={{ display: "flex", flexDirection: 'row', alignItems: 'center' }}>
                    <img src={NoIcon} width={20} height={20} alt='Norsk' />
                    {!shouldUseSmallerStyles && <Typography sx={{ ml: 1 }}>Norsk</Typography>}
                </Box>
            </MenuItem>
        </Select>
        </Box>
    );
}

export default LanguageSelect;

