import React from 'react';
import { Box, Typography, FormControlLabel, Checkbox, useMediaQuery, useTheme, styled } from '@mui/material';
import { useSearchContext } from '../../SearchContext';
import { useTranslation } from 'react-i18next';

const CustomCheckbox = styled(Checkbox)(({ theme }) => ({
    '&:hover': {
        backgroundColor: 'transparent',
      },
    '& .MuiSvgIcon-root': {
        color: 'transparent',
        border: '1px solid #E9EBEE',
        borderRadius: '3px',
        boxShadow: 'none',
        width: '20px',
        height: '20px',
      },
      '&.Mui-checked': {
        '& .MuiSvgIcon-root': {
            color: '#D3385C',
            border: 0,
            boxShadow: 'none',
        },
      },
    }));

const PropertyTickBoxes = ({ validationErrors }) => {
    const { hasBasement, setHasBasement, isGroundFloor, setIsGroundFloor, experiencedSewageBackup, setExperiencedSewageBackup } = useSearchContext();
    const theme = useTheme();
    const { t } = useTranslation();
    const isSmallerScreen = useMediaQuery(theme.breakpoints.down('md'));

    const confirmBasement = (event) => {
        setHasBasement({
            ...hasBasement,
            [event.target.name]: event.target.checked,
        });
    };

    const confirmGroundFloor = (event) => {
        setIsGroundFloor({
            ...isGroundFloor,
            [event.target.name]: event.target.checked,
        });
    };

    const confirmSewageBackup = (event) => {
        setExperiencedSewageBackup({
            ...experiencedSewageBackup,
            [event.target.name]: event.target.checked,
        });
    };

    return (
        <Box>
            <Box 
                sx={{ 
                    display: 'flex', 
                    alignItems: 'center', 
                    animation: validationErrors.hasBasement ? 'shake 0.5s ease-in-out' : 'none',
                    color: validationErrors.hasBasement ? 'primary.main' : 'inherit',
                }}
            >
                <Typography variant="body1" color="info" sx={{ fontSize: isSmallerScreen ? '0.75rem' : '1rem' }}>
                    {t('main_page.does_property_have_basement')}
                </Typography>
                {validationErrors.hasBasement && <Typography color="primary" variant="body2" sx={{ ml: 1 }}>*</Typography>}
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                <FormControlLabel
                    label={t('main_page.yes')}
                    name='yes'
                    control={<CustomCheckbox checked={hasBasement.yes} onChange={confirmBasement} disabled={hasBasement.no || hasBasement.idk} />}
                    sx={{ '.MuiTypography-root': { fontSize: isSmallerScreen ? '0.75rem' : '1rem' } }}
                />
                <FormControlLabel
                    label={t('main_page.no')}
                    name='no'
                    control={<CustomCheckbox checked={hasBasement.no} onChange={confirmBasement} disabled={hasBasement.yes || hasBasement.idk} />}
                    sx={{ '.MuiTypography-root': { fontSize: isSmallerScreen ? '0.75rem' : '1rem' } }}
                />
                <FormControlLabel
                    label={t('main_page.i_don_t_know')}
                    name='idk'
                    control={<CustomCheckbox checked={hasBasement.idk} onChange={confirmBasement} disabled={hasBasement.yes || hasBasement.no} />}
                    sx={{ '.MuiTypography-root': { fontSize: isSmallerScreen ? '0.75rem' : '1rem' } }}
                />
            </Box>

            <Box 
                sx={{ 
                    display: 'flex', 
                    alignItems: 'center', 
                    mt: isSmallerScreen ? 0 : 1,
                    animation: validationErrors.isGroundFloor ? 'shake 0.5s ease-in-out' : 'none',
                    color: validationErrors.isGroundFloor ? 'primary.main' : 'inherit',
                }}
            >
                <Typography variant="body1" color="info" sx={{ fontSize: isSmallerScreen ? '0.75rem' : '1rem' }}>
                {t('main_page.is_property_ground_floor')}
                </Typography>
                {validationErrors.isGroundFloor && <Typography color="primary" variant="body2" sx={{ ml: 1 }}>*</Typography>}
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                <FormControlLabel
                    label={t('main_page.yes')}
                    name='yes'
                    control={<CustomCheckbox checked={isGroundFloor.yes} onChange={confirmGroundFloor} disabled={isGroundFloor.no || isGroundFloor.idk} />}
                    sx={{ '.MuiTypography-root': { fontSize: isSmallerScreen ? '0.75rem' : '1rem' } }}
                />
                <FormControlLabel
                    label={t('main_page.no')}
                    name='no'
                    control={<CustomCheckbox checked={isGroundFloor.no} onChange={confirmGroundFloor} disabled={isGroundFloor.yes || isGroundFloor.idk}/>}
                    sx={{ '.MuiTypography-root': { fontSize: isSmallerScreen ? '0.75rem' : '1rem' } }}
                />
                <FormControlLabel
                    label={t('main_page.i_don_t_know')}
                    name='idk'
                    control={<CustomCheckbox checked={isGroundFloor.idk} onChange={confirmGroundFloor} disabled={isGroundFloor.yes || isGroundFloor.no} />}
                    sx={{ '.MuiTypography-root': { fontSize: isSmallerScreen ? '0.75rem' : '1rem' } }}
                />
            </Box>

            <Box 
                sx={{ 
                    display: 'flex', 
                    alignItems: 'center', 
                    mt: isSmallerScreen ? 0 : 1,
                    animation: validationErrors.experiencedSewageBackup ? 'shake 0.5s ease-in-out' : 'none',
                    color: validationErrors.experiencedSewageBackup ? 'primary.main' : 'inherit',
                }}
            >
                <Typography variant="body1" color="info" sx={{ fontSize: isSmallerScreen ? '0.75rem' : '1rem' }}>
                {t('main_page.experienced_sewage_backup')}
                </Typography>
                {validationErrors.experiencedSewageBackup && <Typography color="primary" variant="body2" sx={{ ml: 1 }}>*</Typography>}
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', mb: isSmallerScreen ? 0 : 1 }}>
                <FormControlLabel
                    label={t('main_page.yes')}
                    name='yes'
                    control={<CustomCheckbox checked={experiencedSewageBackup.yes} onChange={confirmSewageBackup} disabled={experiencedSewageBackup.no || experiencedSewageBackup.idk} />}
                    sx={{ '.MuiTypography-root': { fontSize: isSmallerScreen ? '0.75rem' : '1rem' } }}
                />
                <FormControlLabel
                    label={t('main_page.no')}
                    name='no'
                    control={<CustomCheckbox checked={experiencedSewageBackup.no} onChange={confirmSewageBackup} disabled={experiencedSewageBackup.yes || experiencedSewageBackup.idk} />}
                    sx={{ '.MuiTypography-root': { fontSize: isSmallerScreen ? '0.75rem' : '1rem' } }}
                />
                <FormControlLabel
                    label={t('main_page.i_don_t_know')}
                    name='idk'
                    control={<CustomCheckbox checked={experiencedSewageBackup.idk} onChange={confirmSewageBackup} disabled={experiencedSewageBackup.yes || experiencedSewageBackup.no} />}
                    sx={{ '.MuiTypography-root': { fontSize: isSmallerScreen ? '0.75rem' : '1rem' } }}
                />
            </Box>
        </Box>
    );
}

export default PropertyTickBoxes;
