import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Popper from '@mui/material/Popper';
import { useTranslation } from 'react-i18next';
import InfoIcon from '../../images/icons/more_info_icon.svg';
import { getRiskLevelText, getRiskColor } from './formattingUtils';

// Define risk levels in order of severity for sorting
const riskLevelOrder = {
    "very high": 4,
    "high": 3,
    "medium": 2,
    "low": 1,
    "no risk": 0
};

const RiskLevels = ({ riskLevels }) => {
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = useState(null);
    const infoIconRef = useRef(null);

    const handlePopoverOpen = (event) => {
        setAnchorEl(infoIconRef.current);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const riskAttributes = [
        { key: "basement", label: "main_page.basement" },
        { key: "obstruction", label: "main_page.obstruction" },
        { key: "inundation", label: "main_page.inundation" },
        { key: "runoff", label: "main_page.runoff" }
    ];

    const riskItems = riskAttributes.map(({ key, label }) => {
        const riskValue = riskLevels[key];
        return {
            label: t(label),
            text: getRiskLevelText(riskValue, t),
            color: getRiskColor(riskValue),
            value: riskValue // Keeping the risk value for sorting
        };
    });

    // Sort the risk items by the risk level order in descending order
    const sortedRiskItems = riskItems.sort((a, b) => riskLevelOrder[b.value] - riskLevelOrder[a.value]);

    return (
        <Paper
            sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                height: 'auto',
                border: '0.5px solid #E9EBEE',
                mt: 2,
            }}
        >
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant='h5' sx={{ mb: 1 }}>{t("pdf.contents_page.current_risk")}</Typography>
                <img
                    src={InfoIcon}
                    alt="info-icon"
                    ref={infoIconRef}
                    style={{ cursor: 'pointer' }}
                    onMouseEnter={handlePopoverOpen}
                    onMouseLeave={handlePopoverClose}
                />
            </Box>
            {sortedRiskItems.map((risk, index) => (
                <Box key={index} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt:1.5 }}>
                    <Typography variant='subtitle2'>{risk.label}</Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography sx={{ fontSize: '14px', fontWeight: 500 }}>{risk.text}</Typography>
                        <Typography sx={{ color: risk.color, ml: 0.5, fontSize: '20px', fontWeight: 800 }}>•</Typography>
                    </Box>
                </Box>
            ))}
            <Popper open={Boolean(anchorEl)} anchorEl={anchorEl} placement="top" disablePortal style={{ zIndex: 1300 }}>
                <Paper sx={{ padding: 1, maxWidth: 175, wordWrap: 'break-word', boxShadow: '0px 0px 23px 0px rgba(0, 0, 0, 0.10)', lineHeight: '13.2px' }}>
                    <Typography variant='poppers' >{t("poppers.current_risk")}</Typography>
                </Paper>
            </Popper>
        </Paper>
    );
};

RiskLevels.propTypes = {
    riskLevels: PropTypes.shape({
        have_basement: PropTypes.number,
        basement: PropTypes.string.isRequired,
        obstruction: PropTypes.string.isRequired,
        inundation: PropTypes.string.isRequired,
        runoff: PropTypes.string.isRequired
    }).isRequired
};

export default RiskLevels;
