import { useEffect, useState } from "react";
import { Box, Paper, MenuList, MenuItem } from "@mui/material";

export const SearchResults = ({ results, setResults, address, setAddress, searchByAddress }) => {
  const token = localStorage.getItem('token');
  const [resultChosen, setResultChosen] = useState(false);

  const OnClickHandle = (e) => {
    const result = e.target.textContent;
    setAddress(result);
    setResultChosen(true);
    setResults([]);
  };

  useEffect(() => {
    fetchAddresses(address);
  }, [address]);

  if (address === "") {
    setResults([]);
    setResultChosen(false);
  }

  const fetchAddresses = async (address) => {
    if (address) {
      await fetch(`${process.env.REACT_APP_API_BASE_URL}auto-complete/google/${address}/`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      })
        .then((response) => response.json())
        .then((json) => {
          setResults(json['suggested_addresses']);
        });
    } else {
      setResults([]);
    }
  };

  return (
    <Paper sx={{ 
      position: 'absolute', 
      top: '100%', 
      left: 0, 
      right: 0, 
      zIndex: 1000,
      maxHeight: '200px', // Optional: To limit the height of the dropdown
      overflowY: 'auto' // Optional: To make it scrollable if there are too many results
    }}>
      {searchByAddress && !resultChosen && results && results.length >= 1 && results.map((result, id) => (
        <MenuList key={id}>
          <MenuItem onClick={(e) => OnClickHandle(e)}>
            {result}
          </MenuItem>
        </MenuList>
      ))}
    </Paper>
  );
};
