import React from 'react';
import { Box, Table, TableHead, TableRow, TableCell, TableBody, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { getRiskColor } from '../formattingUtils';

const SolutionsTable = ({ sortedRiskSolutions, riskLevels, userLang }) => {
    const { t } = useTranslation();

    const formatRisks = (risks) => {
        return risks.map((risk, index) => {
            const riskLevel = riskLevels[risk];
            const color = getRiskColor(riskLevel);
            const background = `${color}33`; // 20% opacity
            const border = `1px solid ${color}`;

            return (
                <Box
                    key={index}
                    sx={{
                        display: 'inline-block',
                        borderRadius: '3px',
                        border: border,
                        background: background,
                        padding: '2px 8px',
                        margin: '2px',
                        whiteSpace: 'nowrap',
                        maxWidth: 'fit-content',
                    }}
                >
                    <Typography variant="riskTag">
                        {t(`main_page.${risk}`)}
                    </Typography>
                </Box>
            );
        });
    };

    return (
        <Box sx={{ marginLeft: 2 }}>
        <Table >
            <TableHead>
                <TableRow sx={{ backgroundColor:'#F9FAFB', borderBottom: '1px solid var(--Gray-200, #E4E7EC);' }}>
                    <TableCell >
                        <Typography variant="riskTag">
                        {t('pdf.table_header_solution')}
                        </Typography>
                    </TableCell>
                    <TableCell>
                        <Typography variant="riskTag">
                        {t('pdf.table_header_coverage')}
                        </Typography>
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {sortedRiskSolutions.map((solution) => (
                    <TableRow key={solution[`name_${userLang}`]}>
                        <TableCell key={solution[`name_${userLang}`]}>
                            <Typography variant="subtitle3">{solution[`name_${userLang}`]}</Typography>
                            <Typography variant="h6" color='#475467'>{solution[`short_description_${userLang}`]}</Typography>
                        </TableCell>
                        <TableCell key={solution.risks}>
                            <Typography variant="subtitle3">{formatRisks(solution.risks)}</Typography>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
        </Box>
    );
}

export default SolutionsTable;